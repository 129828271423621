import { t } from '@lingui/macro'
import {
  Bubble,
  ConversationId,
  Conversation as ConversationType,
} from 'common/types'
import React, { useRef, useState } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder-2'
import { increment, serverTimestamp } from 'shared/firebase/serverValue'
import { FacilityId, UserId } from 'shared/types/utils'
import { ms } from 'shared/utils/time'
import { useUser } from '../../components/UserProvider'
import { push, set, update } from '../../firebaseMethods'
import Mic from '../../icons/mic.svg?react'
import Send from '../../icons/send.svg?react'
import Spinner from '../../icons/spinner.svg?react'
import Trash from '../../icons/trash.svg?react'
import { uploadSound } from './fileUploader'

const MAX_AUDIO_DURATION = ms(20, 'seconds')

interface Props {
  conversationId: ConversationId | undefined
  userId: UserId
  facilityId: FacilityId
}

export const Composer: React.FC<Props> = ({
  conversationId,
  userId,
  facilityId,
}) => {
  const { uid } = useUser()

  const [text, setText] = useState('')
  const [blob, setBlob] = useState<Blob>()
  const [error, setError] = useState<string>()

  const audioTimer = useRef<NodeJS.Timeout>()

  const { status, startRecording, stopRecording, clearBlobUrl, mediaBlobUrl } =
    useReactMediaRecorder({
      audio: true,
      onStop: (_blobUrl: string, blob: Blob) => setBlob(blob),
    })

  function addBubbleToConversation(bubble: Bubble) {
    if (conversationId) {
      push(`conversations/${facilityId}/${conversationId}`, bubble)
      update(`discussions/${facilityId}/${userId}/${uid}`, {
        unreadCount: increment(),
      })
    } else {
      // Create a new conversation
      const conversationId = push(
        `conversations/${facilityId}`,
        {} as ConversationType,
      ).key
      push(`conversations/${facilityId}/${conversationId}`, bubble)
      set(`discussions/${facilityId}/${uid}/${userId}`, {
        conversationId,
        unreadCount: 0,
      })
      set(`discussions/${facilityId}/${userId}/${uid}`, {
        conversationId,
        unreadCount: 1,
      })
    }
  }

  async function sendMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setError(undefined)

    const bubbleBase = {
      timestamp: serverTimestamp(),
      userId: uid,
    }

    let bubble: Bubble
    if (blob) {
      const [soundPath, soundURL] = await uploadSound(blob)
      if (!soundURL) {
        setError(t`Erreur lors de l'envoi`)
        return
      }
      bubble = { ...bubbleBase, soundURL, soundPath }
    } else if (text) {
      bubble = { ...bubbleBase, text }
    } else return

    addBubbleToConversation(bubble)

    setText('')
    clearAudio()
  }

  function startAudioRecording() {
    if (audioTimer.current) clearTimeout(audioTimer.current)
    audioTimer.current = setTimeout(stopAudioRecording, MAX_AUDIO_DURATION)
    startRecording()
  }

  function stopAudioRecording() {
    stopRecording()
    clearTimeout(audioTimer.current)
  }

  function clearAudio() {
    setBlob(undefined)
    clearBlobUrl()
    setError(undefined)
  }

  return (
    <form
      method="post"
      onSubmit={sendMessage}
      className="flex flex-row items-center space-x-3"
    >
      <div className="flex h-14 flex-1 flex-row items-center justify-center overflow-x-hidden">
        {error ? (
          <div className="text-background-emergency dark:text-background-emergency-dark">
            {error}
          </div>
        ) : status === 'acquiring_media' ? (
          <Spinner fill="currentColor" className="h-6 w-6 animate-spin" />
        ) : status === 'recording' || status === 'stopping' ? (
          <RecordingPulse />
        ) : status === 'stopped' && mediaBlobUrl ? (
          <audio src={mediaBlobUrl} controls controlsList="nodownload" />
        ) : (
          <textarea
            value={text}
            onChange={(event) => setText(event.target.value)}
            className=" bg-background-phone dark:bg-background-phone-dark w-full flex-1 rounded-lg bg-opacity-50 px-2 py-3 focus:outline-none dark:bg-opacity-50"
            placeholder={t`Votre message...`}
            rows={2}
          />
        )}
      </div>

      {status === 'stopped' && mediaBlobUrl ? (
        <div
          className="bg-background-phone dark:bg-background-phone-dark rounded-full p-3"
          onClick={clearAudio}
        >
          <Trash stroke="currentColor" className="h-6 w-6" />
        </div>
      ) : (
        text.trim().length === 0 && (
          <div
            className="bg-background-phone dark:bg-background-phone-dark rounded-full p-3"
            onMouseDown={startAudioRecording}
            onTouchStart={startAudioRecording}
            onTouchEnd={stopAudioRecording}
            onTouchCancel={stopAudioRecording}
            onMouseUp={stopAudioRecording}
            onMouseOut={stopAudioRecording}
          >
            <Mic fill="currentColor" className="h-6 w-6" />
          </div>
        )
      )}

      {(text || blob) && (
        <button
          type="submit"
          className="bg-background-phone dark:bg-background-phone-dark rounded-full p-3"
        >
          <Send fill="currentColor" className="h-6 w-6" />
        </button>
      )}
    </form>
  )
}

function RecordingPulse() {
  return (
    <div className="h-10 py-2">
      <span className="relative flex h-5 w-5">
        <span className="bg-background-notification dark:bg-background-notification-dark absolute inline-flex h-full w-full animate-ping rounded-full opacity-75"></span>
        <span className="bg-background-notification dark:bg-background-notification-dark relative inline-flex h-5 w-5 rounded-full"></span>
      </span>
    </div>
  )
}
