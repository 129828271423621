import { Trans } from '@lingui/macro'
import React from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { DialogButton } from '../../components/Button'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'

export const ConfirmAlertHideDialog: React.FC<{
  deferred: Deferred<void>
}> = ({ deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div className="text-center">
        <Trans>Enlever cette alerte ?</Trans>
      </div>
      <div className="flex flex-row space-x-4 self-center">
        <DialogButton onClick={() => deferred.reject(USER_CLOSED_DIALOG)}>
          <Trans>Annuler</Trans>
        </DialogButton>
        <DialogButton onClick={() => deferred.resolve()}>
          <Trans>Confirmer</Trans>
        </DialogButton>
      </div>
    </Dialog>
  )
}
