import { Trans, t } from '@lingui/macro'
import { translateAlertDisplayText } from 'common/alert.i18n'
import { alertHandling } from 'common/alertQueries'
import { DateTime } from 'luxon'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { AlertType, Ownership, enableAudioPlayer } from 'shared/types/alert'
import { ms2sec, noonDate } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { Audit } from '../../components/Audit'
import { DialogButton, ToggleDialogButton } from '../../components/Button'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { DialogInput } from '../../components/Input'
import { Player } from '../../components/Player'
import { useUser } from '../../components/UserProvider'
import { NO_ALERT_ID } from '../../constants'
import { remove, set } from '../../firebaseMethods'
import { AlertContext } from './AlertContext'
import { ConfirmAlertHideDialog } from './ConfirmAlertHideDialog'

export const Alert: React.FC<{
  alert: Alert
  serial: string
  alertId: string
}> = ({ alert, serial, alertId }) => {
  const alertRef = useRef<HTMLDivElement>(null)

  const { notificationAlertId } = useContext(AlertContext)

  const { uid } = useUser()

  const [confirmAlertHideDeferred, setConfirmAlertHideDeferred] = useState<{
    deferred: Deferred<void>
  } | null>(null)

  const [maxPlayProgress, setMaxPlayProgress] = useState(0)

  const alertDateTime = DateTime.fromISO(alert.date)
  const date = noonDate(alertDateTime)

  const onPlayPauseEvent = useCallback(
    (isPlaying: boolean, progress: number) => {
      if (isNaN(progress)) return

      setMaxPlayProgress((maxPlayProgress) =>
        Math.max(progress, maxPlayProgress),
      )

      if (window.Android?.onPlayProgress) {
        window.Android.onPlayProgress(NO_ALERT_ID, serial, isPlaying, progress)
      }
    },
    [serial],
  )

  const onPlayProgress = useCallback((progress: number) => {
    if (isNaN(progress)) return
    setMaxPlayProgress((maxPlayProgress) => Math.max(progress, maxPlayProgress))
  }, [])

  async function handleHideAlert(isUseful?: boolean) {
    try {
      if (isUseful === undefined) {
        const deferred = new Deferred<void>()
        setConfirmAlertHideDeferred({ deferred })
        await deferred.promise
      }

      await set(
        `alerts/${date}/${serial}/${alertId}/handling/${uid}`,
        alertHandling(maxPlayProgress, isUseful),
      )

      if (isUseful !== undefined) {
        if (window.Android?.onAlertEvaluated) {
          const alertTime = alertDateTime.valueOf()
          const delay = Math.round(ms2sec(Date.now() - alertTime)) // seconds
          window.Android.onAlertEvaluated(NO_ALERT_ID, serial, isUseful, delay)
        }
      }
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setConfirmAlertHideDeferred(null)
    }
  }

  async function takeAlertOwnership() {
    const ownership: Ownership = {
      uid,
      startTS: serverTimestamp(),
    }
    await set(`alerts/${date}/${serial}/${alertId}/ownership`, ownership)
  }

  async function releaseAlertOwnership() {
    await remove(`alerts/${date}/${serial}/${alertId}/ownership`)
  }

  async function evaluateAlert(isUseful: boolean | undefined, comment: string) {
    await Promise.all([
      handleHideAlert(isUseful),
      set(
        `alerts/${date}/${serial}/${alertId}/ownership/endTS`,
        serverTimestamp(),
      ),
      comment.trim().length > 0
        ? set(`alerts/${date}/${serial}/${alertId}/comment`, comment)
        : null,
    ])
  }

  const evaluationForbidden =
    maxPlayProgress < 0.3 && enableAudioPlayer(alert.type)

  // One time effect that should run only once per alert
  useEffect(() => {
    const doScroll = notificationAlertId === alertId
    if (doScroll)
      alertRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [notificationAlertId, alertId])

  return (
    <>
      {confirmAlertHideDeferred && (
        <ConfirmAlertHideDialog
          {...confirmAlertHideDeferred}
        ></ConfirmAlertHideDialog>
      )}
      <div
        ref={alertRef}
        className={`${alertBackgroundColor(alert.type)} text-alert dark:text-alert-dark relative flex flex-col space-y-3 rounded-xl p-3`}
      >
        <div className="flex flex-row flex-wrap items-center space-x-4">
          {alert.auditUrl && (alert.auditUrlExpiration ?? 0) >= Date.now() ? (
            <Audit auditUrl={alert.auditUrl} />
          ) : enableAudioPlayer(alert.type) ? (
            <Player
              soundURI={alert.soundURL}
              onPlayPauseEvent={onPlayPauseEvent}
              onPlayProgress={onPlayProgress}
            />
          ) : null}
          <div>
            <b>{alertDateTime.toLocaleString(DateTime.TIME_SIMPLE)}</b>
          </div>
          <div>{translateAlertDisplayText(alert)}</div>
        </div>
        <AlertHandling
          alert={alert}
          takeAlertOwnership={takeAlertOwnership}
          releaseAlertOwnership={releaseAlertOwnership}
          evaluateAlert={evaluateAlert}
          evaluationForbidden={evaluationForbidden}
        />
      </div>
    </>
  )
}

const AlertHandling: React.FC<{
  alert: Alert
  takeAlertOwnership: () => void
  releaseAlertOwnership: () => void
  evaluateAlert: (useful: boolean | undefined, comment: string) => void
  evaluationForbidden: boolean
}> = ({
  alert,
  takeAlertOwnership,
  releaseAlertOwnership,
  evaluateAlert,
  evaluationForbidden,
}) => {
  const [isUseful, setIsUseful] = useState<boolean>()
  const [comment, setComment] = useState('')
  const { uid } = useUser()

  return alert.ownership === undefined ? (
    <div className="my-3 flex flex-row justify-center">
      <DialogButton onClick={takeAlertOwnership}>
        <Trans>Je m'en charge&nbsp;!</Trans>
      </DialogButton>
    </div>
  ) : alert.ownership.uid === uid ? (
    <div className="flex flex-col space-y-3">
      <div className="relative">
        <div className="flex flex-col space-y-3">
          <div className="flex flex-row justify-around space-x-1 text-lg">
            <ToggleDialogButton
              disabled={evaluationForbidden}
              selected={isUseful === false}
              onClick={() => setIsUseful(false)}
            >
              <Trans>Fausse alerte</Trans>
            </ToggleDialogButton>
            <ToggleDialogButton
              disabled={evaluationForbidden}
              selected={isUseful === true}
              onClick={() => setIsUseful(true)}
            >
              <Trans>Alerte utile</Trans>
            </ToggleDialogButton>
          </div>
          <DialogInput
            type="text"
            placeholder={t`Votre avis (optionnel)`}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </div>
        {evaluationForbidden && (
          <div
            className={`${alertBackgroundColor(alert.type)} absolute inset-0 bg-opacity-80 pt-2 text-center text-base dark:bg-opacity-80`}
          >
            <Trans>Écoutez le son avant d'évaluer</Trans>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-around">
        <DialogButton onClick={() => releaseAlertOwnership()}>
          <Trans>Annuler</Trans>
        </DialogButton>
        <div className="flex-1" />
        <DialogButton
          disabled={evaluationForbidden}
          onClick={() => evaluateAlert(isUseful, comment)}
        >
          <Trans>Valider</Trans>
        </DialogButton>
      </div>
    </div>
  ) : (
    <div className="text-center">
      <Trans>Alerte prise en charge</Trans>
    </div>
  )
}

function alertBackgroundColor(alertType: AlertType) {
  if (alertType === 'WAKEUP')
    return 'bg-background-alert-wakeup dark:bg-background-alert-wakeup-dark'
  return 'bg-background-alert dark:bg-background-alert-dark'
}
