import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { isDomicile } from 'common/isDomicile'
import { RoomState } from 'common/roomState'
import { DateTime } from 'luxon'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Alerts as AlertsType,
  alertCategory,
  notHighlightedAlertTypes,
} from 'shared/types/alert'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'
import { AsyncState, DataState } from 'shared/types/asyncState'
import { ROLE_DURATION } from 'shared/types/audit'
import { DeviceStatus, Zone } from 'shared/types/fleet'
import { TimeRange } from 'shared/types/timeRange'
import { isDeviceMonitored, isStatusOOO } from 'shared/utils/device'
import { asFirebaseKey } from 'shared/utils/firebase'
import { isCommonRoom } from 'shared/utils/room'
import { ms } from 'shared/utils/time'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { Deferred } from 'shared/utils/web/deferred'
import { alertIsInZone } from 'shared/utils/zone'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { get } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import { useLongPress } from '../../hooks/useLongPress'
import Awake from '../../icons/awake.svg?react'
import Exlamation from '../../icons/error.svg?react'
import Loading from '../../icons/loading.svg?react'
import Sleeping from '../../icons/sleeping.svg?react'
import TV from '../../icons/tv.svg?react'
import { AlertContext } from './AlertContext'
import { Alerts } from './Alerts'
import { ChangeRoomNameDialog } from './ChangeRoomNameDialog'
import { RoomNamesContext } from './RoomNamesContext'

const LATEST_ALERT_DISPLAY_DURATION = ms(5, 'minutes')
const ALERT_DISPLAY_DURATION = 120 // minutes

export const Room: React.FC<{
  serial: string
  room: string
  status: DeviceStatus
  alertsDate: string
  facility: string
  zone: Zone | undefined
  monitoringTimeRange: TimeRange
}> = ({
  serial,
  room,
  status,
  alertsDate,
  facility,
  zone,
  monitoringTimeRange,
}) => {
  const { onAlertReceived, onAlertUpdated } = useContext(AlertContext)
  const lastReceivedAlertId = useRef<FirebaseKey | undefined>(undefined)

  const [changeRoomNameDeferred, setChangeRoomNameDeferred] =
    useState<Deferred<string> | null>(null)

  const { setRoomName, roomNames } = useContext(RoomNamesContext)
  const roomName = roomNames[asFirebaseKey(room)] ?? ''

  const [yesterdayAlerts, setYesterdayAlerts] = useState<AlertsType>({})

  useEffect(() => {
    async function runAsync() {
      const yesterday = DateTime.fromISO(alertsDate)
        .minus({ day: 1 })
        .toISODate()
      setYesterdayAlerts(await get(`alerts/${yesterday}/${serial}`))
    }

    runAsync()
  }, [alertsDate, serial])

  const { data: alerts } = useFirebase(`alerts/${alertsDate}/${serial}`)
  const roomState = useFirebase(`roomStates/${serial}`)

  const changeRoomName = useCallback(async () => {
    const deferred = new Deferred<string>()

    setChangeRoomNameDeferred(deferred)

    try {
      const newRoomName = await deferred.promise
      setRoomName(room, newRoomName)
    } catch (error) {
      if (error !== USER_CLOSED_DIALOG) {
        throw error
      }
    } finally {
      setChangeRoomNameDeferred(null)
    }
  }, [setRoomName, room])

  const handleLongPress = useCallback(async () => {
    await changeRoomName()
  }, [changeRoomName])

  const longPressHandlers = useLongPress(handleLongPress, ms(1, 'second'))

  const last24hAlertEntries = useMemo(() => {
    return Object.entries({ ...yesterdayAlerts, ...alerts }).filter(
      ([_key, alert]) => {
        // Only keep a sliding 24h window (used if isMonitoring) of alerts
        // also in facility and device monitoring time range
        const alertDateTime = DateTime.fromISO(alert.date)
        return (
          alertDateTime.diffNow().as('hour') > -24 &&
          isWithinTimeRange(alertDateTime, monitoringTimeRange)
        )
      },
    )
  }, [yesterdayAlerts, alerts, monitoringTimeRange])

  const zoneAlertEntries = useMemo(
    () =>
      last24hAlertEntries.filter(
        ([_key, alert]) =>
          zone === undefined ||
          alertIsInZone(
            room,
            DateTime.fromISO(alert.date),
            alertCategory(alert.type),
            zone,
          ),
      ),
    [last24hAlertEntries, room, zone],
  )

  const validatedAlertEntries = useMemo(
    () =>
      zoneAlertEntries.filter(
        ([_key, alert]) => alert.validation?.isValidated === true,
      ),
    [zoneAlertEntries],
  )

  const isAlertHandled = useCallback(
    (alert: Alert) => alert.ownership?.uid !== undefined,
    [],
  )

  const isAlertEnded = useCallback(
    (alert: Alert) => alert.ownership?.endTS !== undefined,
    [],
  )

  const displayedAlertEntries = useMemo(() => {
    const alertDisplayDuration = isDomicile(facility)
      ? ROLE_DURATION
      : ALERT_DISPLAY_DURATION // minutes

    return (
      validatedAlertEntries
        // Not already handled by this user or the alert owner
        .filter(([, alert]) => !isAlertEnded(alert))
        // and recent enough
        .filter(
          ([, alert]) =>
            DateTime.fromISO(alert.date).diffNow().as('minutes') >=
            -alertDisplayDuration,
        )
    )
  }, [validatedAlertEntries, isAlertEnded, facility])

  // Send latest alert to the global AlertContext
  useEffect(() => {
    const lastValidatedAlertEntry = validatedAlertEntries
      .sort(([_key1, alert1], [_key2, alert2]) => alertSorter(alert1, alert2))
      .pop()

    if (lastValidatedAlertEntry) {
      const [alertId, alert] = lastValidatedAlertEntry
      if (alertId !== lastReceivedAlertId.current) {
        lastReceivedAlertId.current = alertId
        onAlertReceived(
          serial,
          alertId,
          alert.date,
          isAlertEnded(alert),
          room,
          roomName,
        )
      }

      // To stop notification, call onAlertUpdated on every alert update
      // so that the alert ownership is tracked
      onAlertUpdated(alert.date, isAlertHandled(alert))
    }
  }, [
    validatedAlertEntries,
    serial,
    room,
    roomName,
    onAlertReceived,
    onAlertUpdated,
    isAlertEnded,
    isAlertHandled,
  ])

  const [forceRefresh, setForceRefresh] = useState(0)

  const latestUnvalidatedAlert = useMemo(() => {
    // Should not happen. Only added to make sure the hook is re-run
    if (forceRefresh < 0) return undefined

    return (
      zoneAlertEntries
        .map(([_key, alert]) => alert)
        // NOT validated
        .filter((alert) => !alert.validation?.isValidated)
        .filter((alert) => !notHighlightedAlertTypes.includes(alert.type))
        .filter(
          (alert) =>
            // TODO FIXME Brittle text comparison
            !alert.comment || !alert.comment.includes('Ignored cumulative'),
        )
        .filter(
          (alert) =>
            DateTime.fromISO(alert.date).diffNow().as('milliseconds') >=
            -LATEST_ALERT_DISPLAY_DURATION,
        )
        .sort(alertSorter)
        .pop()
    )
  }, [zoneAlertEntries, forceRefresh])

  const showLatestUnvalidatedAlert =
    latestUnvalidatedAlert !== undefined && displayedAlertEntries.length === 0

  useEffect(() => {
    if (showLatestUnvalidatedAlert) {
      // Note diffNow is a negative value
      const delay =
        LATEST_ALERT_DISPLAY_DURATION +
        DateTime.fromISO(latestUnvalidatedAlert.date)
          .diffNow()
          .as('milliseconds')

      const timer = setTimeout(() => {
        setForceRefresh(Date.now())
      }, delay)
      return () => clearTimeout(timer)
    }
    return
  }, [showLatestUnvalidatedAlert, latestUnvalidatedAlert])

  const roomRef = useRef<HTMLDivElement>(null)

  // Scroll room into view when a new event gets displayed
  useEffect(() => {
    if (showLatestUnvalidatedAlert) {
      roomRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [showLatestUnvalidatedAlert])

  // PERSONALIZATION
  if (roomState.data !== null && facility === 'DEMO-OSO')
    setRandomRoomState(roomState)

  const reduceOpacity =
    roomState.error ||
    roomState.data?.isOffline ||
    !isDeviceMonitored({ status })

  return (
    <>
      <div
        ref={roomRef}
        className={`flex select-none flex-col space-y-2 rounded-lg px-2 py-2 ${
          reduceOpacity ? 'opacity-50' : 'opacity-100'
        } ${
          showLatestUnvalidatedAlert
            ? 'bg-background-alert dark:bg-background-alert-dark bg-opacity-20 dark:bg-opacity-20'
            : 'bg-background-room dark:bg-background-room-dark'
        }`}
        {...longPressHandlers}
      >
        <div className="flex cursor-pointer flex-row items-center space-x-2">
          <div className="flex min-w-0 flex-1 flex-row items-baseline justify-start space-x-2">
            <span className="truncate">{room}</span>
            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap font-bold">
              {roomName}
            </span>
          </div>
          <RoomStatus
            room={room}
            status={status}
            roomState={roomState}
            latestAlert={
              showLatestUnvalidatedAlert ? latestUnvalidatedAlert : undefined
            }
          />
        </div>
        <Alerts serial={serial} alertEntries={displayedAlertEntries} />
      </div>
      {changeRoomNameDeferred && (
        <ChangeRoomNameDialog
          room={room}
          initialRoomName={roomName}
          deferred={changeRoomNameDeferred}
        />
      )}
    </>
  )
}

const RoomStatus: React.FC<{
  room: string
  status: DeviceStatus
  roomState: AsyncState<RoomState | undefined>
  latestAlert: Alert | undefined
}> = ({ room, status, roomState, latestAlert }) => {
  const { _: lingui } = useLingui()

  if (roomState.loading) return <Loading className="w-8" fill="currentColor" />

  if (roomState.error) return <Exlamation className="w-8" fill="currentColor" />

  if (isStatusOOO(status))
    return (
      <div className="text-end text-base">
        <Trans>Attente maintenance</Trans>
      </div>
    )

  if (status === 'disabled')
    return (
      <div className="text-end text-base">
        <Trans>Service désactivé</Trans>
      </div>
    )

  if (roomState.data?.isOffline)
    return (
      <div className="text-end text-base">
        <Trans>Hors ligne</Trans>
      </div>
    )

  if (latestAlert)
    return (
      <div className="flex min-w-0 flex-initial flex-row items-baseline justify-end">
        <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-base">
          {lingui(ALERT_TYPE_TRANSLATE[latestAlert.type])}
        </span>
      </div>
    )

  if (roomState.data?.isTV)
    return <TV className="w-8" fill="currentColor" stroke="currentColor" />

  const hideSleepingIcon = isCommonRoom(room)

  if (hideSleepingIcon) return <div className="w-8" />

  if (roomState.data?.isSleeping)
    return <Sleeping className="w-8" fill="currentColor" />

  if (roomState.data?.isSilence)
    return (
      <div className="text-end text-base">
        <Trans>Silencieux</Trans>
      </div>
    )

  return <Awake className="w-8" fill="currentColor" />
}

function setRandomRoomState(roomState: DataState<RoomState | undefined>) {
  roomState.data = {
    isTV: Math.random() > 0.8,
    isOffline: Math.random() > 0.95,
    isSleeping: Math.random() > 0.4,
  }
}

function alertSorter({ date: date1 }: Alert, { date: date2 }: Alert) {
  return DateTime.fromISO(date1).valueOf() - DateTime.fromISO(date2).valueOf()
}
